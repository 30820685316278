export default {
  'user.logo.title': 'Hua Yue Auto Transport',
  'user.login.title': 'Determined to become a leader and pioneer in the auto transport industry',
  'user.login.userName': 'userName',
  'user.login.password': 'password',
  'user.login.username.placeholder': 'username',
  'user.login.password.placeholder': 'password',
  'user.login.message-invalid-credentials':
    'Invalid username or password',
  'user.login.message-invalid-verification-code': 'Invalid verification code',
  'user.login.tab-login-credentials': 'Credentials',
  'user.login.tab-login-mobile': 'Mobile number',
  'user.login.mobile.placeholder': 'Mobile number',
  'user.login.mobile.verification-code.placeholder': 'Verification code',
  'user.login.remember-me': 'Remember me',
  'user.login.forgot-password': 'Forgot your password?',
  'user.login.sign-in-with': 'Sign in with',
  'user.login.signup': 'Sign up',
  'user.login.login': 'Login',
  'user.register.register': 'Register',
  'user.register.email.placeholder': 'Email',
  'user.register.password.placeholder': 'Password ',
  'user.register.password.popover-message': 'Please enter at least 6 characters. Please do not use passwords that are easy to guess. ',
  'user.register.confirm-password.placeholder': 'Confirm password',
  'user.register.get-verification-code': 'Get code',
  'user.register.sign-in': 'Already have an account?',
  'user.register-result.msg': 'Account：registered at {email}',
  'user.register-result.back-home': 'Back to home',
  'user.register-result.view-mailbox': 'View mailbox',
  'user.email.required': 'Please enter your email!',
  'user.email.wrong-format': 'The email address is in the wrong format!',
  'user.userName.required': 'Please enter username',
  'user.password.required': 'Please enter your password!',
  'user.password.twice.msg': 'The passwords entered twice do not match!',
  'user.password.strength.msg': 'The password is not strong enough',
  'user.password.strength.strong': 'Strength: strong',
  'user.password.strength.medium': 'Strength: medium',
  'user.password.strength.low': 'Strength: low',
  'user.password.strength.short': 'Strength: too short',
  'user.confirm-password.required': 'Please confirm your password!',
  'user.phone-number.required': 'Please enter your phone number!',
  'user.phone-number.wrong-format': 'Please enter a valid phone number',
  'user.verification-code.required': 'Please enter the verification code!'
}
