export default {
  'menu.login': 'login',
  'menu.home': 'Home',

  'menu.system': 'system',
  'menu.system.position': 'position',
  'menu.system.role': 'role',
  'menu.system.organzition': 'organzition',
  'menu.system.employee': 'employee',

  'menu.flow': 'workFlow',
  'menu.flow.myFlow': 'myFlow',
  'menu.flow.myPending': 'myPending',
  'menu.flow.myProcessed': 'myProcessed',

  'menu.crm': 'crm',
  'menu.crm.customer': 'customer',
  'menu.crm.customer.addOrder': 'customerOrder',
  'menu.crm.carrier': 'carrier',

  'menu.order': 'orderCenter',
  'menu.order.internal': 'internal',
  'menu.order.internal.add': 'order-add',
  'menu.order.internal.edit': 'order-edit',
  'menu.order.internal.detail': 'order-detail',
  'menu.order.positionInfo.detail': 'positionInfo.detail',
  'menu.order.positionInfo': 'positionInfo',

  'menu.transport': 'transportCenter',
  'menu.transport.car': 'carList',
  'menu.transport.bigcar': 'bigcarList',
  'menu.transport.vehicle': 'vehicle',
  'menu.transport.gps': 'gpsList',
  'menu.transport.offer': 'offer',
  'menu.transport.offerXls': 'offerXls',
  'menu.transport.line': 'line',

  'menu.finance': 'financeCenter',
  'menu.finance.income': 'income',
  'menu.finance.income.list': 'income-list',
  'menu.finance.compositeReport': 'compositeReport',
  'menu.finance.incomeExpenditureSituation': 'incomeExpenditureSituation',
  'menu.finance.settlement.carrier': 'settlementCarrier',
  'menu.finance.settlement.customer': 'settlementCustomer',
  'menu.finance.settlement.tip': 'settlementTip',
  'menu.finance.settlement.other': 'settlementOther',
  'menu.finance.profitReport': 'profitReport',
  'menu.finance.bill.carrier': 'carrierBill',
  'menu.finance.arichive': 'arichive',

  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',

  'menu.opsCenter': 'opsCenter',
  'menu.opsCenter.opsApplication': 'opsApplication',
  'menu.opsCenter.dailyPoster': 'dailyPoster',
  'menu.opsCenter.opsRevise': 'opsRevise'
}
