export default {
  submit: 'Submit',
  save: 'Save',
  'submit.ok': 'Submit successfully',
  'save.ok': 'Saved successfully',
  fullscreen: '退出全屏',
  'fullscreen.ok': '全屏',
  columnTitle: 'Column display',
  columnReset: 'Reset',
  tabsBar: {
    closeLeft: 'close the left',
    closeRight: 'close the right',
    closeOther: 'close other',
    closeAll: 'close all'
  }
}
